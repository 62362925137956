module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"TangerineRepublic","icon":"src/assets/images/favicon.png","start_url":"https://www.tangerinerepublic.com/","display":"standalone","background_color":"#f46a6c","theme_color":"#4b1375","scope":"https://www.tangerinerepublic.com/","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"45d3dec0f5e853db4dae0d91bbce4442"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
